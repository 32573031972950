import {
  fetchDel,
  fetchGet,
  fetchPost,
  fetchPut,
} from "../../../utils/request";
import { makeAutoObservable } from "mobx";
import {
  ReportListModel,
  ReportModel,
  ReportWholeModel,
  WeekModel,
} from "./model/report.model";
import React from "react";
import {
  fromListStore,
  fromObjStore,
  fromPageListStore,
  fromSubmitStore,
} from "mobx-template";
import { OrderType } from "../../../model/order-type";
import { MediaSelModel } from "../../user/setting/store/model/setting.model";
import { AccountListModel } from "../../account/account/store/model/account.model";

export class ReportStore {
  static apiPrefix = "/report";

  constructor() {
    makeAutoObservable(this);
  }

  timeSpecificationCheckStatus: number[] = [0];

  // 更新
  updateTimeSpecificationCheckStatus(index: number, value: number) {
    this.timeSpecificationCheckStatus = this.timeSpecificationCheckStatus.map(
      (item, i) => (i === index ? value : item)
    );
    console.log(this.timeSpecificationCheckStatus);
  }

  // 添加
  addTimeSpecificationCheckStatus(value: number) {
    this.timeSpecificationCheckStatus = [
      ...this.timeSpecificationCheckStatus,
      value,
    ];
    console.log(this.timeSpecificationCheckStatus);
  }

  // 删除
  removeTimeSpecificationCheckStatus(index: number) {
    this.timeSpecificationCheckStatus =
      this.timeSpecificationCheckStatus.filter((_, i) => i !== index);
    console.log(this.timeSpecificationCheckStatus);
  }

  mediaSelListStore = fromListStore<MediaSelModel>((params) =>
    fetchGet("/common/media/codes")
  );

  agencySelListStore = fromListStore<MediaSelModel>((params) =>
    fetchGet("/common/agency/codes")
  );

  listStore = fromPageListStore<ReportListModel, { orderType: OrderType }>(
    (params) => fetchPost(`${ReportStore.apiPrefix}/list`, params)
  );

  editStore = fromSubmitStore<ReportModel>((body: ReportModel) => {
    const fetch = body.id ? fetchPut : fetchPost;
    return fetch(`${ReportStore.apiPrefix}`, body);
  });

  detailStore = fromObjStore<ReportModel, { id: string }>(
    ({ id }) => fetchGet(`${ReportStore.apiPrefix}/${id}`),
    {
      successCallback: (data: ReportModel) => {
        this.timeSpecificationCheckStatus = data.exportConditions.map(
          (item) => item.timeSpecification
        );
      },
    }
  );

  delStore = fromSubmitStore<{ id: string }>(({ id }) =>
    fetchDel(`${ReportStore.apiPrefix}/${id}`)
  );

  viewWholeStore = fromObjStore<ReportWholeModel, { id: string }>(({ id }) =>
    fetchGet(`${ReportStore.apiPrefix}/view/${id}/whole`)
  );

  weekStore = fromListStore<WeekModel, { id: string }>(({ id }) =>
    fetchGet(`${ReportStore.apiPrefix}/${id}/week/codes`)
  );

  dateStore = fromListStore<string, { id: string }>(({ id }) =>
    fetchGet(`${ReportStore.apiPrefix}/${id}/date/codes`)
  );

  viewWeekStore = fromObjStore<any, { id: string; week: string }>(
    ({ id, week }) =>
      fetchGet(`${ReportStore.apiPrefix}/view/${id}/week/${week}`)
  );

  viewDateStore = fromObjStore<any, { id: string; date: string }>(
    ({ id, date }) =>
      fetchGet(`${ReportStore.apiPrefix}/view/${id}/date/${date}`)
  );

  agencyUsersStore = fromListStore<AccountListModel, { id: string }>(({ id }) =>
    fetchGet(`/agency/${id}/user/all`)
  );
}

export const ReportContext = React.createContext<ReportStore>(null!);
