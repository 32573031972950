import { fetchDel, fetchPost, fetchPut } from "../../../../utils/request";
import { makeAutoObservable } from "mobx";
import React from "react";
import {
  fromModalStore,
  fromPageListStore,
  fromSubmitStore,
} from "mobx-template";
import { AgencyUserModel } from "./model/agency-user.model";

export class AgencyUserStore {
  readonly apiPrefix = "/agency";

  constructor(private id: string) {
    makeAutoObservable(this);
  }

  listStore = fromPageListStore<AgencyUserModel>((params) =>
    fetchPost(`${this.apiPrefix}/${this.id}/user/list`, params)
  );

  editStore = fromSubmitStore<AgencyUserModel>((body: AgencyUserModel) => {
    const fetch = body.id ? fetchPut : fetchPost;
    return fetch(`${this.apiPrefix}/${this.id}/user`, body);
  });

  modalStore = fromModalStore();

  delUserStore = fromSubmitStore<{ id: string; agencyID: string }>(
    ({ id }) => fetchDel(`${this.apiPrefix}/${this.id}/user/${id}`),
    {
      successCallback: () => {
        this.listStore.reload();
      },
    }
  );
}

export const AgencyUserContext = React.createContext<AgencyUserStore>(null!);
