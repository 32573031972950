import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row, Statistic, Card } from "antd";
import EchartsPie from "../../components/EchartsPie";
import EchartsRadar from "../../components/EchartsRadar";
import EchartsStackedLine from "../../components/EchartsStackedLine";
import { ReportContext } from "../../store/report-store";
import { useParams } from "react-router-dom";
import GenderIcon from "../../../../components/GenderIcon";
import EchartsBar from "../../components/EchartsBar";
import EchartsPieForGenderRate from "../../components/EchartsPieForGenderRate";

const AdPeriod = () => {
  const params = useParams();

  const {
    viewWholeStore: { data: reportWhole, loadData: loadReportWholeData },
  } = useContext(ReportContext);

  useEffect(() => {
    if (params?.id) {
      loadReportWholeData({ id: params?.id }).then();
    }
  }, [loadReportWholeData, params?.id]);

  const getMovingAndStayingProportion = () => {
    return [
      {
        name: "歩行者",
        value: reportWhole?.movingAndStaying?.series
          ? reportWhole?.movingAndStaying?.series[0]
          : 0,
      },
      {
        name: "滞留者",
        value: reportWhole?.movingAndStaying?.series
          ? reportWhole?.movingAndStaying?.series[1]
          : 0,
      },
    ];
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card size={"small"} title={"広告視聴可能人数・視聴態度推移"}>
            <EchartsStackedLine data={reportWhole?.avsTrendGraph} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title="広告視聴可能人数">
            <span className="subRedTtl">
              <Statistic
                className="gender-num"
                value={reportWhole?.totalImpression || 0}
                prefix={<span></span>}
                suffix="人"
              />
            </span>
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title="男性">
            <Statistic
              className="gender-num"
              value={reportWhole?.maleImpression || 0}
              prefix={
                <GenderIcon
                  gender="male"
                  width={24}
                  height={24}
                  color="#1890ff"
                />
              }
              suffix="人"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title="女性">
            <Statistic
              className="gender-num"
              value={reportWhole?.femaleImpression || 0}
              prefix={
                <GenderIcon
                  gender="female"
                  width={24}
                  height={24}
                  color="#ff0000"
                />
              }
              suffix="人"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"性別構成"}>
            <EchartsPieForGenderRate data={reportWhole?.genderProportion} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"男性（年代構成）"}>
            <EchartsPie data={reportWhole?.ageProportionMale} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"女性（年代構成）"}>
            <EchartsPie data={reportWhole?.ageProportionFemale} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"進行方向分布"}>
            <EchartsRadar data={reportWhole?.angleRadar} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"AVSポイント(進行方向係数)"}>
            <EchartsBar data={reportWhole?.avsPointGraph} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"歩行者・滞留者比率"}>
            <EchartsPie data={getMovingAndStayingProportion()} />
          </Card>
        </Col>
        <Col span={24} className={"no_show"}>
          <div style={{ height: "200px" }}></div>
        </Col>
        <div className="print_tail" style={{ left: 0, top: "3313px" }}>
          <p className={"page_number"}>2/3</p>
        </div>
        <Col span={16}>
          <Card size={"small"} title={"インプレッション"}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card size={"small"} type="inner" title={"インプレッション数"}>
                  <Statistic
                    className="gender-num"
                    value={reportWhole?.impressionPoint || 0}
                    prefix={<span></span>}
                    suffix="imp"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  size={"small"}
                  type="inner"
                  title={"インプレッション単価"}
                >
                  <Statistic
                    className="gender-num"
                    value={reportWhole?.impressionUnitPrice || 0}
                    prefix={<span></span>}
                    suffix="円"
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card size={"small"} title={"費用対効果"}>
            <Card size={"small"} type="inner" title={"広告視聴単価"}>
              <Statistic
                className="gender-num"
                value={reportWhole?.cpv || 0}
                prefix={<span></span>}
                suffix="円"
              />
            </Card>
          </Card>
        </Col>
        {/*<Col span={4}>*/}
        {/*  <Card title="視認者数">*/}
        {/*    <Statistic*/}
        {/*      value={reportWhole?.viewerCount || 0}*/}
        {/*      suffix="人"*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Col>*/}
        {/*<Col span={4}>*/}
        {/*  <Card title="視認単価">*/}
        {/*    <Statistic*/}
        {/*      value={reportWhole?.viewUnitPrice || 0}*/}
        {/*      suffix="円"*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Col>*/}
      </Row>
    </>
  );
};

export default observer(AdPeriod);
