import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import './index.scss';
import Setting from './compontent/Setting';
import { getCurrentMenus } from '../../../router/menu-data';
import { LayoutContext, LayoutStore } from './store/layout-store';
import { AuthContext } from '../../../store/auth-store';
import Menus from './compontent/Menus';
import { Footer } from 'antd/es/layout/layout';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
} from '@ant-design/icons';
import { useMyNavigate } from '../../../core/navigate';
import { useLoginListener } from '../../../hooks/use-login';
import { useIndexPath, useRoleType } from '../../../hooks/use-auth';
import { useAliveClear } from '../../../core/keep-alive/use-keep-alive';

const {Content, Sider} = Layout;

const Layouts = () => {

	let navigate = useMyNavigate();
	let roleType = useRoleType();
	let indexPath = useIndexPath();

	const [collapsed, setCollapsed] = useState(false);

	const menuList = getCurrentMenus(roleType);

	// load userInfo
	const {userStore: {loadData}} = useContext(AuthContext);

	useEffect(() => {
		loadData().then();
	}, [loadData]);

	// Listening request status 401 jump to login page
	useLoginListener();

	// clear alive
	useAliveClear();

	return <LayoutContext.Provider value={new LayoutStore(useContext(AuthContext))}>
		<Layout className="layoutArea">
			<Layout className="mainCont">
				<Sider className="siderArea noPrint" trigger={null} collapsible collapsed={collapsed}>
					{collapsed == false && (
						<div className="logoFold" onClick={() => navigate(indexPath(roleType))}><img src="/logo_header.jpg" width="168px"/></div>
					)}
					{collapsed == true && (
						<div className="logoFold" onClick={() => navigate(indexPath(roleType))}>OOH<sup>β</sup></div>
					)}
					<Menus list={menuList} collapsed={collapsed}/>
					<div className="copyright">
					{collapsed == false && (
						<>
							<div>Search powered by</div>
							<div><img src="/logo_idea.gif" height="40px"/></div>
						</>
					)}
					</div>
					<div className={collapsed ? 'siderTool toolUnfold' : 'siderTool'}>
						<MenuFoldOutlined onClick={() => setCollapsed(true)}/>
						<MenuUnfoldOutlined onClick={() => setCollapsed(false)}/>
					</div>
				</Sider>
				<Layout className="rightLayout">
					<Setting/>
					<Content className={collapsed ? 'mainArea mainSubArea' : 'mainArea'}>
						<Outlet/>
						<Footer className={collapsed ? 'footerArea footerFold noPrint' : 'footerArea noPrint'}>
							Ⓒ 2022 OOH Analytics
						</Footer>
					</Content>
				</Layout>
			</Layout>
		</Layout>
	</LayoutContext.Provider>;
};

export default observer(Layouts);
